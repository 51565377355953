import { createSlice } from '@reduxjs/toolkit';

const validPlatforms = ['web', 'android', 'ios'];

const initialState = {
  asicsId: '',
  agreeTermsCountry: '',
  agreeTermsVersion: '',
  autoOptIn: false,
  bounce: '',
  codeChallenge: '',
  codeChallengeMethod: '',
  email: '',
  grantType: '',
  locale: '',
  maxCookieTimeout: undefined,
  noConfirmEmail: false,
  platform: 'web',
  prefillEmail: '',
  privacyHubUrl: 'https://www.asics.com/privacy',
  redirectUri: '',
  region: '',
  scope: '',
  sdkVersion: '',
  sendConfirmEmail: true,
  state: '',
  style: '',
  thirdPartyAuth: true,
  token: undefined,
  utmCampaign: undefined,
  utmContent: undefined,
  utmMedium: undefined,
  utmSource: undefined,
  utmTerm: undefined,
  webview: false,
};

/**
 * TODO: i'm not the biggest fan of shoving all query params into this slice. something to noodle on
 */
export const queryParamsSlice = createSlice({
  name: 'queryParams',
  initialState,
  reducers: {
    setOneTimeUseQueryParams: (state, action) => {
      state.asicsId = action.payload?.asicsId;
      state.autoOptIn = action.payload?.autoOptIn;
      state.codeChallenge = action.payload?.codeChallenge;
      state.codeChallengeMethod = action.payload?.codeChallengeMethod;
      state.prefillEmail = action.payload?.prefillEmail;
      state.redirectUri = action.payload?.redirectUri;
      state.scope = action.payload?.scope;
      state.state = action.payload?.state;
      state.token = action.payload?.token;
      state.utmCampaign = action.payload?.utmCampaign;
      state.utmContent = action.payload?.utmContent;
      state.utmMedium = action.payload?.utmMedium;
      state.utmSource = action.payload?.utmSource;
      state.utmTerm = action.payload?.utmTerm;
    },
    setPersistableQueryParams: (state, action) => {
      const platform = action.payload?.platform;

      state.agreeTermsCountry = action.payload?.agreeTermsCountry ?? state.agreeTermsCountry;
      state.agreeTermsVersion = action.payload?.agreeTermsVersion ?? state.agreeTermsVersion;
      state.bounce = action.payload?.bounce || '';
      state.email = action.payload?.email ? decodeURIComponent(action.payload.email) : '';
      state.grantType = action.payload?.grantType ?? state.grantType;
      state.locale = action.payload?.locale ?? state.locale;
      state.maxCookieTimeout = action.payload?.maxCookieTimeout ?? state.maxCookieTimeout;
      state.noConfirmEmail = action.payload?.noConfirmEmail
        ? action.payload?.noConfirmEmail?.toLowerCase() === 'true'
        : state.noConfirmEmail;
      state.platform = validPlatforms.includes(platform) ? platform : 'web';
      state.privacyHubUrl = action.payload?.privacyHubUrl || initialState.privacyHubUrl;
      state.region = action.payload?.region ?? state.region;
      state.sdkVersion = action.payload?.sdkVersion ?? state.sdkVersion;
      state.sendConfirmEmail = action.payload?.sendConfirmEmail
        ? action.payload?.sendConfirmEmail?.toLowerCase() === 'true'
        : state.sendConfirmEmail;
      state.style = action.payload?.style ?? state.style;
      state.thirdPartyAuth = action.payload?.thirdPartyAuth
        ? action.payload?.thirdPartyAuth.toLowerCase() === 'true'
        : state.thirdPartyAuth;
      state.webview = action.payload?.webview
        ? action.payload?.webview?.toLowerCase() === 'true'
        : state.webview;
    },
  },
});

export const {
  setOneTimeUseQueryParams,
  setPersistableQueryParams,
} = queryParamsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default queryParamsSlice.reducer;
